<template>
  <div>
    <!--  mobile view -->
    <q-item class="no-padding" v-if="$q.screen.xs">
      <div v-if="card">
        <q-item-section side v-if="card">
          <q-icon color="success" :name="card.typeIcon()" size="lg"/>
        </q-item-section>
        <q-item-section v-if="card">
          <span class="text-subtitle1"> {{card.type().split(' ')[0]}} ****{{card.get('last_four')}}</span>
          <q-btn size="sm" class="no-pointer-events" outline color="green-13" :label="transaction.type()"/>
        </q-item-section>
      </div>
      <div v-if="hasCreditTxns && !card">
        <q-item-section style="width:140px"></q-item-section>
      </div>

      <q-item-section avatar>
        <span class="text-h6 text-bold text-light-blue-7"> - {{transaction.get('amount') | currency}}</span>
        <span class="text-body1 text-grey-6">{{transaction.get('created_at') | date}}</span>
      </q-item-section>
    </q-item>
    <!-- end mobile view -->

    <!--  desktop view -->
    <q-item class="no-padding" v-else>
      <div v-if="hasCreditTxns">
        <q-item-section side>
          <q-icon v-if="card" color="success" :name="card.typeIcon()" size="lg"/>
        </q-item-section>
        <q-item-section class="credit-card-number">
          <span v-if="card" class="text-subtitle1"> {{card.type().split(' ')[0]}} ****{{card.get('last_four')}}</span>
        </q-item-section>
        <q-item-section>
          <q-btn v-if="card" size="sm" class="no-pointer-events" outline color="green-13" :label="transaction.type()"/>
        </q-item-section>
      </div>

      <q-item-section>
        <span class="text-body1 text-grey-6">{{transaction.get('created_at') | date}}</span>
      </q-item-section>
      <q-item-section avatar>
        <span class="text-h6 text-bold text-light-blue-7"> - {{transaction.get('amount') | currency}}</span>
      </q-item-section>
    </q-item>
    <!-- end desktop view -->
  </div>
</template>

<script>
export default {
  name: 'transaction',
  props: {
    transaction: {
      type: Object,
      require: true
    },
    hasCreditTxns: {
      type: Boolean
    }
  },
  computed: {
    card () {
      return this.$store.state.cards.cards ? this.$store.state.cards.cards.find(c => c.id() === this.transaction.get('card_id')) : null
    }
  }
}
</script>

<style scoped>
  .credit-card-number {
    width: calc(100vw / 3);
  }
</style>
