<template>
  <q-page class="q-pl-lg q-pr-lg">
    <div class="row items-center q-gutter-md" v-if="!loading">

      <q-card class="transaction-card">

        <div v-if="transactions.length > 0">
          <q-card-section class="bg-grey-3 no-padding">
            <q-item >
              <q-item-section avatar>
                <q-icon name="cp_tnxs" size="md" />
              </q-item-section>
              <q-item-section class="text-subtitle1 text-bold">
                Transaction History
              </q-item-section>
            </q-item>
          </q-card-section>

          <div :key="i" v-for="(transaction, i) of currentItems">
            <q-card-section>
              <Transaction :transaction="transaction" :has-credit-txns="hasCreditTxns"/>
            </q-card-section>
            <q-separator/>
          </div>
        </div>

        <div v-else class="text-center q-pa-lg q-ma-lg text-grey-6">
          <q-card-section>
            <q-icon name="cp_missile" size="150px"/>
          </q-card-section>
          <q-card-section >
                <span class="text-h5 text-bold">No transaction yet</span>
                <br>
                <span class="text-subtitle1 text-bold">We don’t see any records from your history.</span>
          </q-card-section>
        </div>

      </q-card>

      <pagination :per-page="itemsInPage" :records="transactions.length" v-model="currentPage"/>

    </div>
  </q-page>
</template>

<script>
import failedRequest from '../mixins/failed-request'
import Transaction from '../components/Transaction'
import Pagination from '../components/Pagination'

export default {
  name: 'PageTransactions',
  meta: {
    title: 'Transactions'
  },
  props: {
    itemsInPage: {
      type: Number,
      default: 8
    }
  },
  components: {
    Transaction,
    Pagination
  },
  mixins: [failedRequest],
  async mounted () {
    this.$q.loading.show({
      delay: 400
    })

    try {
      await this.$store.dispatch('transactions/getTransactions')
      await this.$store.dispatch('cards/getCards')
      this.loading = false
    } catch (e) {
      this.failedRequestNotification()
    }

    this.$q.loading.hide()
  },
  data () {
    return {
      currentPage: 0,
      loading: true
    }
  },
  computed: {
    transactions () {
      return this.$store.state.transactions.transactions ? this.$store.state.transactions.transactions : []
    },
    hasCreditTxns () {
      return this.currentItems.filter(txn => !!txn.get('card_id')).length > 0
    },
    pages () {
      return parseInt(this.transactions.length / this.itemsInPage) + 1
    },
    currentItems () {
      let currentItem = this.currentPage * this.itemsInPage
      return this.transactions.slice(currentItem, currentItem + this.itemsInPage)
    }
  }
}
</script>

<style>
  .transaction-card {
    width: 100% !important;
    max-width: 100% !important;
  }
</style>
